import React, {useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ProductContext } from '../ProductProvider';
import logo from '../images/logo/logo-new.png';
import { RoutesLocal } from '../routes';
import { signOut } from 'aws-amplify/auth';
import { useSnackbar } from 'notistack';

export default function Topbar() {
   const { loggedUser, setLoggedUser, isSidebarOpen, setIsSidebarOpen} = useContext(ProductContext);
   const { enqueueSnackbar } = useSnackbar();
   const navigate=useNavigate();

   const toggleSidebar = () => {
     setIsSidebarOpen(!isSidebarOpen);
   };

   const handleSignOut=async(event)=>{
      event.preventDefault();
      //console.log("inside sighout");
      try{
         await signOut()
         .then((res)=>{
          setLoggedUser({...loggedUser,
            name:'',
            email:'',
           // currentStatus:'',
            DOB:'',
            profile:'',
            provinceName:'',
            website:'',
            isAuthenticated:false
          }); 
          enqueueSnackbar("Logged Out Successfully",{variant : 'success'});
          navigate(RoutesLocal.Signin.path);
  
        }).catch((error)=>{
         enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        })
      }catch(error){
         enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
      }
    }
  
 
  return (
   <div>
      <div className={!isSidebarOpen?"topbar":"topbar_max"}>
         <nav className="navbar-expand-lg navbar-light">
            <div className="full">
               <button type="button" id="sidebarCollapse" className='sidebar_toggle' onClick={toggleSidebar}><i className="fa fa-bars"></i></button>
               <div className="logo_section">
                  <a href="#">
                     {/*<img className="img-responsive"  style={{background:"white", borderRadius:"25px"}} src={logo} alt="#" />*/}
                     <span><div  style={{color:"white"}}>Admin Office - Vidyajyoti Journal</div></span></a>
               </div>
               <div className="right_topbar">
                  <div className="icon_info">
                    {/*} <ul>
                        <li><a href="#"><i className="fa fa-bell-o"></i><span className="badge">2</span></a></li>
                        <li><a href="#"><i className="fa fa-question-circle"></i></a></li>
                        <li><a href="#"><i className="fa fa-envelope-o"></i><span className="badge">3</span></a></li>
                     </ul>*/}
                     <ul className="user_profile_dd">
                        <li>
                           <a className="dropdown-toggle" data-bs-toggle="dropdown">{/*<img className="img-responsive rounded-circle" style={{background:"white", borderRadius:"25px"}} src={logo} alt="#" />*/}<span className="name_user">{loggedUser.name}</span></a>
                           <div className="dropdown-menu">
                              <a className="dropdown-item" href="#"><Link to={RoutesLocal.MyProfile.path}> <i className="fa fa-user p-2" aria-hidden="true"></i><span>My Profile</span></Link></a>
                              <a className="dropdown-item" href="#"><Link to={RoutesLocal.ResetPassword.path}> <i className="fa fa-unlock p-2" aria-hidden="true"></i><span>Change Password</span></Link></a>
                              {/*<a className="dropdown-item" href="help.html">Help</a>*/}
                              <a className="dropdown-item" href="#" onClick={event=>handleSignOut(event)}><Link to="#"><i className="fa fa-sign-out p-2"></i><span>Log Out</span> </Link></a>
                           </div>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </nav>
      </div>
   </div>
  )
}
