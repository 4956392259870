import React, {useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Preloader from "../../components/Preloader/Preloader";
import { ProductContext } from '../../ProductProvider';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Button, Dropdown, Table, ButtonGroup, Modal, ModalHeader, ModalFooter, ModalBody } from 'react-bootstrap';
import { useSnackbar } from 'notistack';
import SubscriberForm from './SubscriberForm';
import PaymentForm from '../payment/PaymentForm';
import RenewalList from '../renewal/RenewalList';

const config = require('../../config.json');
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default function ListSubscribers(props) {
  const {applicationDetails, setApplicationDetails, status} = props;
  const { loggedUser, validateLogin, subscriberFormType, setSubscriberFormType, setsubscriberDetails} = useContext(ProductContext);
  const [loaded, setLoaded] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  //const [selectedItem, setSelectedItem]=useState('');
  //const [collapsed, setCollapsed] = useState(true);
  const [collapsed, setCollapsed] = useState(true);
  const [newCollapsed, setNewCollapsed] = useState(true);
  //const [subscriberFormType, setSubscriberFormType] =useState('View');
  const [displayResults, setDisplayResults]= useState([]);

useEffect(()=>{
  fetchSubscribersDetails();
  setSubscriberFormType('View');
},[])

const fetchSubscribersDetails=async()=>{
  try{
      setLoaded(false);
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      const clientID="VJJournal";
      const applicationStatus=status;
      await axios.get(`${config.api.invokeUrl}/subscribers/status?clientID=${clientID}&subStatus=${applicationStatus}`,{
          headers: {
            Authorization: idToken,
            'x-api-key': config.api.key
          }}, 
      ).then((response)=>{
        //console.log("Response : ", response);
          setApplicationDetails(response.data);
          enqueueSnackbar(response.data.length + " - " + status + " Subscribers Found",{variant : 'success'});
          setLoaded(true);
      }).catch((error)=>{
          enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
          setLoaded(true);
      })
  
  }catch(error){
    enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    setLoaded(true);
  }
}

const toggle = () => {
  //   console.log("clicked");
       setCollapsed(!collapsed);
}

const newToggle = () => {
//   console.log("clicked");
      setNewCollapsed(!newCollapsed);
}

const handleApplicationView=async(event, item, action)=>{
  event.preventDefault();
  //console.log("item :", item);
  try{
    await setsubscriberDetails(item);
    await setSubscriberFormType(action);
    toggle();
  }catch(error){
    enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
  }
}

const handlePaymentForm=(event, item, action)=>{
  event.preventDefault();
  try{
    setSubscriberFormType(action);
    setsubscriberDetails(item);
    newToggle();
  }catch(error){
    enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
  }

}

const handleEmailProcessing=async(event, item)=>{
  event.preventDefault();
  try{
    if(!item.emailID.match(validRegex)){
      enqueueSnackbar("Invalid Email Address!",{variant : 'info'});
    }else{
      setLoaded(false);
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      const clientID="VJJournal";
      const params={
        clientID:clientID,
        emailID:item.emailID,
        subscriberName:item.subscriberName,
        subscriberID:item.subscriberID,
        endDate:item.endDate,
        copies:item.copies
      }
     // console.log("Params :", params);
      await axios.post(`${config.api.invokeUrl}/notifications/emailrenewal`, params,{
          headers: {
            Authorization: idToken,
            'x-api-key': config.api.key
          }}, 
      ).then((response)=>{
          console.log("Response : ", response);
          //setApplicationDetails(response.data);
          enqueueSnackbar("Email sent Successfully",{variant : 'success'});
          setLoaded(true);
      }).catch((error)=>{
          enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
          setLoaded(true);
      })
    }
  }catch(error){
    enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    setLoaded(true);
  }
}

  return (
    <>
    <Preloader show={loaded ? false : true} />

    {/*View*/}
    <div >
          <Modal onHide={toggle} show={!collapsed} style={{top:'10%', maxWidth:'800px'}} >
          <div >
             <ModalHeader  show={toggle}>
               Registration Form - {subscriberFormType}
                    </ModalHeader>
                    <ModalBody >    
                        <SubscriberForm handleSearch={fetchSubscribersDetails} toggle={toggle} formType={subscriberFormType}/> 
                    </ModalBody>
                    <ModalFooter>
            {' '}
            {/*<ReactToPrint
        content={reactToPrintContentModal}
        documentTitle="AwesomeFileName"
        
        trigger={reactToPrintTriggerModal}
      />*/}
            <Button onClick={toggle}>
                Close
            </Button>
            </ModalFooter>
          </div>
                   
        </Modal>
        </div>

        {/*Payment*/}
        <Modal onHide={newToggle} show={!newCollapsed} style={{top:'10%', maxWidth:'800px'}}>
            <ModalHeader toggle={newToggle}>
            Payment - Add
            </ModalHeader>
            <ModalBody>    
            <div>
            <PaymentForm formType={"New"} newToggle={newToggle}/> 
              </div>
            </ModalBody>
            <ModalFooter>
            {' '}
            <Button onClick={newToggle}>
                Close
            </Button>
            </ModalFooter>
          </Modal>

          <RenewalList applicationDetails={applicationDetails} displayResults={displayResults} setDisplayResults={setDisplayResults}/>

    <Table responsive hover className="user-table align-items-center">
         <thead>
            <tr>
            <th>#</th>
            <th>Subscriber ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>End Date</th>
            <th>Category</th>
            <th>Action</th>
            </tr>
        </thead>
        <tbody>
        {displayResults.map((item, index)=>
        <tr key={index}>
        <th scope="row">{index+1}</th>
        <td>{item.subscriberID}</td>
        <td>{item.subscriberName}</td>
        <td>{item.emailID}</td>
        <td>{item.endDate}</td>
        <td>{item.categoryID}</td>
        <td>
       <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
          <span className="icon icon-sm">
          <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item, "View")}}>
          <i className="fa fa-eye" aria-hidden="true"></i>  View Details
          </Dropdown.Item>
          <Dropdown.Item className="text-success" onClick={(event)=>{handleEmailProcessing(event, item)}} >
          <i className="fa fa-envelope" aria-hidden="true"></i>  Send Email
          </Dropdown.Item>
          <Dropdown.Item className="text-danger" onClick={(event)=>{handlePaymentForm(event, item, "Add")}}>
          <i className="fa fa-plus" aria-hidden="true"></i>  Process Payment
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
        {/*<FontAwesomeIcon icon={faEye} className="me-2" onClick={(event)=>{handleApplicationView(event, item)}}/>
        <FontAwesomeIcon icon={faCheck} className="me-2" onClick={(event)=>{handleApplicationUpdate(event, item,"Approved")}}/>
        <FontAwesomeIcon icon={faCancel} className="me-2" onClick={(event)=>{handleApplicationUpdate(event, item,"Declined")}}/>
        
        <button onClick={(event)=>{handleProjectView(event, item)}}>View</button>
        <button onClick={(event)=>{handleProjectDelete(event, item)}}>Delete</button>
        <button onClick={(event)=>{handleProjectEdit(event, item)}}>Edit</button>
        </td>*/}
        </td>
        </tr>)}
        </tbody>
        </Table>

</>
  )
}
