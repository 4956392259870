import React from 'react';
import ReactDOM from 'react-dom/client';
import {Amplify} from 'aws-amplify';
import { SnackbarProvider } from 'notistack';
import {HelmetProvider} from 'react-helmet-async';

//import './index.css';
import './style.css';
import {BrowserRouter as Router} from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ProductProvider } from './ProductProvider';
import config from './config';
import 'bootstrap/dist/css/bootstrap.min.css';


Amplify.configure ({
  Auth: {
    Cognito: {
      mandatorySignId: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolClientId: config.cognito.APP_CLIENT_ID
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
    <ProductProvider>
    <HelmetProvider>
    <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical:'top', horizontal:'right' }}>
      <App />
    </SnackbarProvider>
    </HelmetProvider>
    </ProductProvider>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
