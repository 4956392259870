import React, {useState, useContext, useEffect} from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import ListSubscribers from './ListSubscribers';
import {Helmet} from 'react-helmet-async';
import ReactToPrint from "react-to-print";
import { Button, ButtonGroup, Breadcrumb} from 'react-bootstrap';
import { CSVLink } from "react-csv";
import {downloadSubscriberHeaders} from '../../ProData';
import { ProductContext } from '../../ProductProvider';

export default function ManageSubscription() {
    const { loggedUser, validateLogin } = useContext(ProductContext);
    const [tabValue, setTabValue]=useState("Renewal");
    const [applicationDetails, setApplicationDetails]=useState([]);
    const componentRef = React.useRef(null);
    const csvLinkEl = React.createRef();
    const [todayMsg, setTodayMsg]= useState('');

    useEffect(()=>{
      if(!loggedUser.isAuthenticated){
        validateLogin();
      }else{
        const ttoday=new Date().getFullYear();
        setTodayMsg("Due for Renewal (" + ttoday + ")");
      }
    },[loggedUser])

    const handleDownload =async(event)=>{
      event.preventDefault();
      csvLinkEl.current.link.click();
    }

    const reactToPrintContent = React.useCallback(() => {
      return componentRef.current;
    }, [componentRef.current]);
  
    const reactToPrintTrigger = React.useCallback(() => {
      return <Button variant="outline-primary"  size="sm">Print</Button>;
    }, []);

  return (
  <>
    <Helmet>
      <title>Manage Subscribers - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Manage Subscribers - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Manage Subscribers - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><i className="fa fa-home" aria-hidden="true"></i></Breadcrumb.Item>
            <Breadcrumb.Item>Subscribers</Breadcrumb.Item>
            <Breadcrumb.Item active>Manage Subscribers</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Manage Subscribers</h4>
       
        </div>

        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
          <ReactToPrint
        content={reactToPrintContent}
        documentTitle="Registration"
        
        trigger={reactToPrintTrigger}
      />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadSubscriberHeaders}
              filename="download.csv"
              data={applicationDetails}
              ref={csvLinkEl}
            />

          </ButtonGroup>
        </div>

      </div>
    <div ref={componentRef} className="table-settings mb-4">
      <Tabs fill defaultActiveKey="Renewal" id="uncontrolled-tab-example"       
        activeKey={tabValue} onSelect={(k) => setTabValue(k)} className="mb-3">
      <Tab eventKey="Renewal" title={todayMsg}>
        {tabValue==="Renewal"?<ListSubscribers applicationDetails={applicationDetails} setApplicationDetails={setApplicationDetails} status="Renewal"/>:null}
      </Tab>
      <Tab eventKey="Active" title="Active">
        {tabValue==="Active"?<ListSubscribers applicationDetails={applicationDetails} setApplicationDetails={setApplicationDetails} status="Active"/>:null}
      </Tab>
      <Tab eventKey="Expired" title="Expired">
        {tabValue==="Expired"?<ListSubscribers applicationDetails={applicationDetails} setApplicationDetails={setApplicationDetails} status="Expired"/>:null}
      </Tab>
      <Tab eventKey="Suspended" title="Suspended">
        {tabValue==="Suspended"?<ListSubscribers applicationDetails={applicationDetails} setApplicationDetails={setApplicationDetails} status="Suspended"/>:null}
      </Tab>
      <Tab eventKey="Cancelled" title="Cancelled">
        {tabValue==="Cancelled"?<ListSubscribers applicationDetails={applicationDetails} setApplicationDetails={setApplicationDetails} status="Cancelled"/>:null}
      </Tab>

    </Tabs>
      </div>
    </>
  )
}
