import React from 'react';
import { Row, Spinner} from 'react-bootstrap';
import { Chart } from "react-google-charts";

//import { CounterWidget, CircleChartWidget, BarChartWidget, TeamMembersWidget, ProgressTrackWidget, RankingWidget, SalesValueWidget, SalesValueWidgetPhone, AcquisitionWidget } from "../../components/Widgets";
//import { ChartsGoogle } from './ChartsGoogle';


export default function GoogleBarChart(props) {
  const {googleData, title} = props;

  const options = {
    chart: {
      title: title,
      subtitle: "Due for Renewal (60 days), Renewed, Expired Subscribers",
    },
  };


  return (
  <>
    {googleData?
    <Row className="justify-content-md-center">
      <Chart
      chartType="Bar"
      width="100%"
      height="400px"
      data={googleData }
      options={options}
    />
    </Row>:<>
  <Spinner
    color="primary"
    type="grow"
  >
    Loading...
  </Spinner>
  <Spinner
    color="secondary"
    type="grow"
  >
    Loading...
  </Spinner>
  <Spinner
    color="success"
    type="grow"
  >
    Loading...
  </Spinner>
  <Spinner
    color="danger"
    type="grow"
  >
    Loading...
  </Spinner>
  <Spinner
    color="warning"
    type="grow"
  >
    Loading...
  </Spinner>
  <Spinner
    color="info"
    type="grow"
  >
    Loading...
  </Spinner>
  <Spinner
    color="light"
    type="grow"
  >
    Loading...
  </Spinner>
  <Spinner
    color="dark"
    type="grow"
  >
    Loading...
  </Spinner>
</>}
    </>
  )
}
