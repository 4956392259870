import React, {useContext} from 'react';
import './App.css';
import Footer from './components/Footer';
import Topbar from './components/Topbar';
import Login from './pages/auth/Login';
import LandingPage from './pages/dashboard/LandingPage';
import MyProfile from './pages/profile/MyProfile';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ProductContext } from './ProductProvider';
import ForgotPassword from './pages/auth/ForgotPassword';
import Sidebar from './components/Sidebar';
import SearchPage from './pages/subscription/SearchPage';
import { RoutesLocal } from './routes';
import ManageSubscription from './pages/subscription/ManageSubscription';
import AccountMgmt from './pages/subscription/AccountMgmt';
import SliderViewer from './pages/ManageImage/SliderViewer';
import IssuePoster from './pages/ImageEditor/issuePoster/IssuePoster';
import PaymentSearch from './pages/payment/PaymentSearch';
import AddPayments from './pages/payment/AddPayments';
import InsightsOverview from './pages/insights/InsightsOverview';
import ViewPDF from './pages/pdfJournal/ViewPDF';
import AddPDF from './pages/pdfJournal/AddPDF';
import SubscriberForm from './pages/subscription/SubscriberForm';
import './style.css';
import ResetPassword from './pages/auth/ResetPassword';
import RenewalList from './pages/renewal/RenewalList';

function App() {
  const { loggedUser, isSidebarOpen} = useContext(ProductContext);

  return (
    <div className="dashboard full_container inner_container">
     {loggedUser.isAuthenticated && <Sidebar />}
      <div id="content" className={!isSidebarOpen?"content":"content_max"}>
       {loggedUser.isAuthenticated && <Topbar /> }

        <Routes>
          <Route path={RoutesLocal.Presentation.path} element={<LandingPage />} />
          <Route path={RoutesLocal.MyProfile.path} element={<MyProfile />} />
          <Route path={RoutesLocal.Signin.path} element={<Login />} />
          <Route path={RoutesLocal.ForgotPassword.path} element={<ForgotPassword />} />
          <Route path={RoutesLocal.ResetPassword.path} element={<ResetPassword />} />
          <Route path={RoutesLocal.Search.path} element={<SearchPage />} />
          <Route path={RoutesLocal.ManageSubscribers.path} element={<ManageSubscription />} />
          <Route path={RoutesLocal.AddSubscriber.path} element={<SubscriberForm />} />
          <Route path={RoutesLocal.AccountMgmt.path} element={<AccountMgmt />} />
          <Route path={RoutesLocal.SliderViewer.path} element={<SliderViewer />} />
          <Route path={RoutesLocal.IssuePoster.path} element={<IssuePoster />} />
          <Route path={RoutesLocal.AddPayments.path} element={<AddPayments />} />
          <Route path={RoutesLocal.PaymentsReport.path} element={<PaymentSearch />} />
          <Route path={RoutesLocal.RenewalList.path} element={<RenewalList />} />
          <Route path={RoutesLocal.Insights.path} element={<InsightsOverview />} />
          <Route path={RoutesLocal.ManagePDF.path} element={<ViewPDF />} />
          <Route path={RoutesLocal.AddPDF.path} element={<AddPDF form="New"/>} />
          <Route path="*" element={<Navigate to="/" replace />}/>
        </Routes>
        <div class="midde_cont">
        <Footer />
        </div>
      </div>
      
    </div>
  );
}

export default App;
