import React, { useState, useRef, useEffect } from "react";
import Preloader from "../../components/Preloader/Preloader";
import { Col, Row, Card, Form, Alert } from 'react-bootstrap';

export default function AddSlider(props) {
    const { setNewFileName, setImageFile, profileImage }=props;
    const inputRef = React.useRef();
    const [loaded, setLoaded] = useState(true);
   
    const handleFileSelection = (event)=>{
      setLoaded(false);
        event.preventDefault();
        if (event.target.files && event.target.files.length > 0) {
          setImageFile(event.target.files[0]);
          var tprofile = document.getElementById("profile");
          tprofile.src=URL.createObjectURL(event.target.files[0]);
      }
      setLoaded(true);
    }

  return (
    <div>
       <Preloader show={loaded ? false : true} />
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Slider information</h5>
        <Form>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Slider Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter Slider name"  onChange={(event)=>{setNewFileName(event.target.value)}}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
          <div style={{textAlign:"center"}}> 
          <img id="profile" src={profileImage} 
         //   onError={handleImageError}
            alt="Project Photo" style={{width:"12rem", height:"12rem"}} />
            <input
          type="file"
          accept="image/*"
          ref={inputRef}
          onChange={handleFileSelection}
         // style={{ display: "none" }}
        />
        </div>
          </Row>
          
        </Form>
      </Card.Body>
    </Card>

    </div>
  )
}
