
export const RoutesLocal = {
    // pages
    Presentation: { path: "/" },
    DashboardOverview: { path: "/dashboard/overview" },

    //auth
    Signin: { path: "/dashboard/sign-in" },
    Signup: { path: "/dashboard/sign-up" },
    ForgotPassword: { path: "/dashboard/forgot-password" },
    ResetPassword: { path: "/dashboard/reset-password" },
    NotFound: { path: "/dashboard/404" },
    ServerError: { path: "/dashboard/500" },
    InProgress: {path:"/dashboard/inprogress"},
    MyProfile: {path:"/dashboard/myprofile"},
    
    //subscriptions
    Search: { path: "/subscription/search" },
    AccountMgmt:{ path:"/subscription/accounts"},
    ManageSubscribers:{ path:"/subscription/manage"},
    AddSubscriber:{ path:"/subscription/add"},

    //payemnts
    AddPayments:{ path:"/payments/add"},
    PaymentsReport:{ path:"/payments/report"},
    RenewalList:{path:"/payments/renewal"},

    //website
    SliderPath:{ path:"/website/slider"},

    //insights
    Insights:{path:"/dashboard/insights"},

    //Images
    ImageViewer: { path: "/imageviewer" },

    //PDF Journal
    ManagePDF:{path:"/journalpdf/manage"},
    AddPDF:{path:"/journalpdf/add"},

    //Images
    SliderViewer: { path: "/dashboard/slider-view" },
    IssuePoster: { path: "/dashboard/issue-poster" },

};