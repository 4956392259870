import React, {useContext, useEffect, useState} from 'react';
import { ProductContext } from '../ProductProvider';
import PerfectScrollbar from 'perfect-scrollbar';
import { Link, useNavigate } from 'react-router-dom';
import { RoutesLocal } from "../routes";
import logo from '../images/logo/logo-new.png';
import '../style.css';
import { signOut } from 'aws-amplify/auth';
import { useSnackbar } from 'notistack';

export default function Sidebar() {
   const { loggedUser, isSidebarOpen, setIsSidebarOpen, setLoggedUser} = useContext(ProductContext);
   const [itemSelect, setItemSelect]=useState("Overview");
    const { enqueueSnackbar } = useSnackbar();
    const navigate=useNavigate();

   useEffect(()=>{
      const ps = new PerfectScrollbar('#menuoptions');
      //console.log("PS:", ps);
      ps.update();
   },[isSidebarOpen])

   const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };

   const handleSignOut=async(event)=>{
      event.preventDefault();
      //console.log("inside sighout");
      try{
         await signOut()
         .then((res)=>{
          setLoggedUser({...loggedUser,
            name:'',
            email:'',
           // currentStatus:'',
            DOB:'',
            profile:'',
            provinceName:'',
            website:'',
            isAuthenticated:false
          }); 
          enqueueSnackbar("Logged Out Successfully",{variant : 'success'});
          navigate(RoutesLocal.Signin.path);
  
        }).catch((error)=>{
         enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        })
      }catch(error){
         enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
      }
    }
  
  return (
    <div>
        <div className="full_container">
         <div className="inner_container">
            <nav id="sidebar" className={isSidebarOpen ? 'active' : ''}>
               <div className="sidebar_blog_1">
                  <div className="sidebar-header">
                     
                     <div className="logo_section">
                        <a href="#"><img className="logo_icon img-responsive" style={{background:"white", borderRadius:"25px"}} src={logo} alt="#" /></a>
                     </div>
                  </div>
                  <div className="sidebar_user_info">
                     <div className="icon_setting"></div>
                     <div className="user_profle_side">
                        <div className="user_img"><img className="img-responsive" style={{background:"white", borderRadius:"25px"}} src={logo} alt="#" /></div>
                        <div className="user_info">
                           <h6>{loggedUser.name}</h6>
                           <p><span className="online_animation"></span> Online</p>
                        </div>
                       
                     </div>
                     
                  </div>
               </div>
               <div id="menuoptions" className="sidebar_blog_2">
                  <h4>Menu  <i className="float-right fa fa-times"  onClick={toggleSidebar}></i></h4> 
                  <ul className="list-unstyled components">
                  <li><a href="#" style={{padding:"5px 0"}}><Link to="/"><i className="fa fa-home" aria-hidden="true"></i><span>Overview</span></Link></a></li>
                  <li className="active">
                        <a href="#dashboard" data-bs-toggle="collapse" aria-expanded="false" className="dropdown-toggle" ><i className="fa fa-users" aria-hidden="true"></i><span>Subscriptions</span></a>
                        <ul className="collapse list-unstyled" id="dashboard">
                           <li><Link to={RoutesLocal.Search.path}><i className="fa fa-search" aria-hidden="true"></i><span>Search</span></Link></li>
                           <li><Link to={RoutesLocal.ManageSubscribers.path}><i className="fa fa-cog" aria-hidden="true"></i><span>Manage Subscribers</span></Link></li>
                           <li><Link to={RoutesLocal.AddSubscriber.path}><i className="fa fa-user-plus" aria-hidden="true"></i><span>Register</span></Link></li>
                           <li><Link to={RoutesLocal.AccountMgmt.path}><i className="fa fa-key" aria-hidden="true"></i><span>Accounts</span></Link></li>
                        </ul>
                     </li>
                     <li >
                        <a href="#journal" data-bs-toggle="collapse" aria-expanded="false" className="dropdown-toggle" onClick={e=>setItemSelect("Journal")}><i className="fa fa-book" aria-hidden="true"></i> <span>Journal</span></a>
                        <ul className="collapse list-unstyled" id="journal">
                           <li><Link to={RoutesLocal.ManagePDF.path}><i className="fa fa-file-pdf-o" aria-hidden="true"></i><span>Manage PDF</span></Link></li>
                           <li><Link to={RoutesLocal.AddPDF.path}><i className="fa fa-plus-square" aria-hidden="true"></i><span>Register</span></Link></li>
                        </ul>
                     </li>
                     <li>
                        <a href="#payments" data-bs-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i className="fa fa-money" aria-hidden="true"></i><span>Payments</span></a>
                        <ul className="collapse list-unstyled" id="payments">
                           <li><Link to={RoutesLocal.AddPayments.path}><i className="fa fa-plus-circle" aria-hidden="true"></i><span>New</span></Link></li>
                           <li><Link to={RoutesLocal.PaymentsReport.path}><i className="fa fa-tachometer" aria-hidden="true"></i><span>Reports</span></Link></li>
                        </ul>
                     </li>
                     <li>
                        <a href="#website" data-bs-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i className="fa fa-globe" aria-hidden="true"></i><span>Websites</span></a>
                        <ul className="collapse list-unstyled" id="website">
                           <li><Link to={RoutesLocal.SliderViewer.path}><i className="fa fa-slideshare" aria-hidden="true"></i><span>Slider</span></Link></li>
                           <li><Link to={RoutesLocal.IssuePoster.path}><i className="fa fa-file-image-o" aria-hidden="true"></i><span>Issue Poster</span></Link></li>
                        </ul>
                     </li>
                     <li>
                        <a href="#insights" data-bs-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i className="fa fa-bar-chart" aria-hidden="true"></i><span>Insights</span></a>
                        <ul className="collapse list-unstyled" id="insights">
                           <li><Link to={RoutesLocal.Insights.path}><i className="fa fa-line-chart" aria-hidden="true"></i><span>Insights</span></Link></li>
                        </ul>
                     </li>
                     <li>
                        <a href="#account" data-bs-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i className="fa fa-user" aria-hidden="true"></i> <span>My Account</span></a>
                        <ul className="collapse list-unstyled" id="account">
                           <li><Link to={RoutesLocal.MyProfile.path}><i className="fa fa-user" aria-hidden="true"></i><span>My Profile</span></Link></li>
                           <li><Link to={RoutesLocal.ResetPassword.path}><i className="fa fa-key" aria-hidden="true"></i><span>Reset password</span></Link></li>
                        </ul>
                     </li>
                     <li><a href="#" style={{padding:"5px 0"}} onClick={event=>handleSignOut(event)}><Link to="#"><i className="fa fa-sign-out"></i><span>Log Out</span></Link></a></li>
                     {/*<li className="active">
                        <a href="#dashboard" data-bs-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i className="fa fa-dashboard yellow_color"></i> <span>Dashboard</span></a>
                        <ul className="collapse list-unstyled" id="dashboard">
                           <li>
                              <a href="dashboard.html"> <span>Default Dashboard</span></a>
                           </li>
                           <li>
                              <a href="dashboard_2.html"> <span>Dashboard style 2</span></a>
                           </li>
                        </ul>
                     </li>
                     <li><a href="widgets.html"><i className="fa fa-clock-o orange_color"></i> <span>Widgets</span></a></li>
                     <li>
                        <a href="#element" data-bs-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i className="fa fa-diamond purple_color"></i> <span>Elements</span></a>
                        <ul className="collapse list-unstyled" id="element">
                           <li><a href="general_elements.html"> <span>General Elements</span></a></li>
                           <li><a href="media_gallery.html"> <span>Media Gallery</span></a></li>
                           <li><a href="icons.html"> <span>Icons</span></a></li>
                           <li><a href="invoice.html"> <span>Invoice</span></a></li>
                        </ul>
                     </li>
                     <li><a href="tables.html"><i className="fa fa-table purple_color2"></i> <span>Tables</span></a></li>
                     <li>
                        <a href="#apps" data-bs-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i className="fa fa-object-group blue2_color"></i> <span>Apps</span></a>
                        <ul className="collapse list-unstyled" id="apps">
                           <li><a href="email.html"> <span>Email</span></a></li>
                           <li><a href="calendar.html"> <span>Calendar</span></a></li>
                           <li><a href="media_gallery.html"> <span>Media Gallery</span></a></li>
                        </ul>
                     </li>
                     <li><a href="price.html"><i className="fa fa-briefcase blue1_color"></i> <span>Pricing Tables</span></a></li>
                     <li>
                        <a href="contact.html">
                        <i className="fa fa-paper-plane red_color"></i> <span>Contact</span></a>
                     </li>
                     <li className="active">
                        <a href="#additional_page" data-bs-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i className="fa fa-clone yellow_color"></i> <span>Additional Pages</span></a>
                        <ul className="collapse list-unstyled" id="additional_page">
                           <li>
                              <a href="profile.html"> <span>Profile</span></a>
                           </li>
                           <li>
                              <a href="project.html"> <span>Projects</span></a>
                           </li>
                           <li>
                              <a href="login.html"> <span>Login</span></a>
                           </li>
                           <li>
                              <a href="404_error.html"> <span>404 Error</span></a>
                           </li>
                        </ul>
                     </li>
                     <li><a href="map.html"><i className="fa fa-map purple_color2"></i> <span>Map</span></a></li>
                     <li><a href="charts.html"><i className="fa fa-bar-chart-o green_color"></i> <span>Charts</span></a></li>
                     <li><a href="settings.html"><i className="fa fa-cog yellow_color"></i> <span>Settings</span></a></li>*/}
                  </ul>
               </div>
            </nav>
           
         </div>
      </div>
    </div>
  )
}
