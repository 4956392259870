import React, {useEffect, useContext, useState} from 'react';
import { Row, Card } from 'react-bootstrap';
import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import Preloader from "../../components/Preloader/Preloader";
import { ProductContext } from '../../ProductProvider';
import {Helmet} from 'react-helmet-async';
import SubscriberInsights from './SubscriberInsights';
import GoogleBarChart from './GoogleBarChart';
import { useSnackbar } from 'notistack';

const config = require('../../config.json');
//const graphSubColours=["color:green","color:orange","color:silver","color:grey","color:red"];


export default function InsightsOverview() {
  const { loggedUser, validateLogin} = useContext(ProductContext);
  const [loaded, setLoaded] = useState(false);
  const [googleSubData, setGoogleSubData] = useState();
  const [googleCatData, setGoogleCatData] = useState();
  const [googleActiveSubData, setGoogleActiveSubData] = useState();
  const { enqueueSnackbar } = useSnackbar();
  

  useEffect(()=>{
    if(!loggedUser.isAuthenticated){
      validateLogin();
      }else{
        fetchSubscriberInsights();
      }
  },[loggedUser])

  const fetchSubscriberInsights=async()=>{
    try{      
          setLoaded(false);
          const { idToken } = (await fetchAuthSession()).tokens ?? {};
          await axios.get(`${config.api.invokeUrl}/insights/category`,{
            headers: {
            Authorization: idToken,
            'x-api-key':config.api.key
          }
        }).then(async(response) => {
         await processSubscriberData(response.data[0],"Category");
         await processSubscriberData(response.data[1],"Subscriber");
         enqueueSnackbar("Subscriber Insights processed successfully!",{variant : 'success'});
         setLoaded(true);
  
      }).catch((error)=>{
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        setLoaded(true);
    })
      }catch(error){
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        setLoaded(true);
      }
    }

const processSubscriberData=(rawData, type)=>{
    var subChartHeader=[];
    const chartHearder=["Status",type];
    subChartHeader.push(chartHearder);
    for(const data in rawData){
        const ttt=[data, rawData[data]];
        subChartHeader.push(ttt);
    }
    if(type==="Subscriber"){
    setGoogleSubData(subChartHeader);
    const tactiveData=subChartHeader.filter(item=>item[0]!=="Cancelled");
    setGoogleActiveSubData(tactiveData);
    }
    else{
    setGoogleCatData(subChartHeader);
    }
}

  return (
    <>
    <Helmet>
      <title>Insights and Analytics - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Insights and Analytics - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Insights and Analytics - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>
    <Preloader show={loaded ? false : true} />
    <div className='container'>Insights Overview</div>
    <Card><Row><SubscriberInsights googleData={googleSubData} title={"Subscriber Status"}/></Row></Card>
    <Card><Row><GoogleBarChart googleData={googleActiveSubData} title={"Active Subscriber Break Up"}/></Row></Card>
    <Card><Row><SubscriberInsights googleData={googleCatData} title={"Category Count"}/></Row></Card>
    </>
  )
}
