import React, { useState, useContext, useEffect } from "react";
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Table } from 'react-bootstrap';
import {Modal, ModalBody, ModalHeader, ModalFooter, FormGroup } from "react-bootstrap";
import Preloader from "../../components/Preloader/Preloader";
import { ProductContext } from '../../ProductProvider';
import axios from "axios";
import { fetchAuthSession } from 'aws-amplify/auth';
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";
import { downloadSubscriberHeaders, journalCategory } from "../../ProData";
import {Helmet} from 'react-helmet-async';
import SubscriberForm from "./SubscriberForm";
import PaymentForm from "../payment/PaymentForm";
import { useSnackbar } from 'notistack';
import '../../style.css';

const config = require('../../config.json');
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default function SearchPage() {
  const { loggedUser, validateLogin, subscriberFormType, setSubscriberFormType, setsubscriberDetails} = useContext(ProductContext);
  const csvLinkEl = React.createRef();
  const [searchTopic, setSearchTopic] = useState('SubscriberID');
  const [searchValue, setSearchValue] = useState('');
  const [loaded, setLoaded] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [displayResults, setDisplayResults] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [newCollapsed, setNewCollapsed] = useState(true);
  const componentRef = React.useRef(null);
  const componentModelRef = React.useRef(null);
  const [isLabelViewChecked, setIsLabelViewChecked] = useState(false);
  const [viewOption,setViewOption] = useState("All");
  const [isActiveView, setIsActiveView] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(()=>{
    if(!loggedUser.isAuthenticated){
      validateLogin();
    }
   // console.log(batchYear);
  },[loggedUser])

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const reactToPrintContentModal = React.useCallback(() => {
    return componentModelRef.current;
  }, [componentModelRef.current]);

  const reactToPrintTriggerModal = React.useCallback(() => {
    return <Button   >Print</Button>;
  }, []);

  const handleApplicationView=async(event, item, action)=>{
    event.preventDefault();
    try{
      await setSubscriberFormType(action);
      setsubscriberDetails(item);
      toggle();
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    }
  }

  const handlePaymentForm=(event, item, action)=>{
    event.preventDefault();
    try{
      setSubscriberFormType(action);
      setsubscriberDetails(item);
      newToggle();
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    }

  }

  const handleDownload =async(event)=>{
    event.preventDefault();
    csvLinkEl.current.link.click();
  }

  const toggle = () => {
    //   console.log("clicked");
         setCollapsed(!collapsed);
  }

  const newToggle = () => {
  //   console.log("clicked");
        setNewCollapsed(!newCollapsed);
  }

  const handleSearch=async()=>{
    //event.preventDefault();
    try{
      if(searchValue===""){
        enqueueSnackbar("Search Value is empty Enter the search input and then hit Search button!",{variant : 'info'});
      }else{
        setLoaded(false);
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        var tsearchValue=searchValue
        if(searchTopic==="SubscriberName"){
          tsearchValue=searchValue.toUpperCase();
          setSearchValue(tsearchValue);
        }
      await axios.get(`${config.api.invokeUrl}/subscribers/globalsearch?fetchKey=${tsearchValue}&fetchOption=${searchTopic}`, {
        headers: {
          Authorization: idToken,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
        //console.log("response : ", response);
        if(response.data.length>0)
        {
            //console.log("order status")
            setSearchResults(response.data);
            //setDisplayResults(response.data);
            processResults(response.data, viewOption, isActiveView);
        }
        enqueueSnackbar(response.data.length + " - Subscriber's records found!",{variant : 'success'});
        setLoaded(true);
    }).catch((error)=>{
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
      setLoaded(true);
  })
}
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
      setLoaded(true);
    }
  }

  const processResults=(rawData, type, activeView)=>{
    //const rawData=searchResults;
    //console.log("filtered data : ", rawData);
    var tempDisplayResults=[];
    if(type==="Email"){
      const filteredData=rawData.filter(item=>item.emailID.match(validRegex));
      tempDisplayResults=filteredData;
      //setDisplayResults(filteredData);
      enqueueSnackbar(filteredData.length + " - Subscriber's records Filtered by Email",{variant : 'success'});
      //console.log("filtered data : ", filteredData);
    }else if(type==="No Email"){
      const filteredData=rawData.filter(item=>!item.emailID.match(validRegex));
      tempDisplayResults=filteredData;
      //setDisplayResults(filteredData);
      enqueueSnackbar(filteredData.length + " - Subscriber's records Filtered by No Email Address",{variant : 'success'});
      //console.log("filtered data : ", filteredData);
    }else{
      tempDisplayResults=rawData;
      //setDisplayResults(rawData);
      enqueueSnackbar(rawData.length + " - Subscriber's records!",{variant : 'success'});  
    }
    if(activeView){
      const filteredData=tempDisplayResults.filter(item=>item.subStatus==="Active");
      tempDisplayResults=filteredData;
      //setDisplayResults(filteredData);
      enqueueSnackbar(filteredData.length + " - Subscriber's records Filtered by Active Accounts",{variant : 'success'});  
    }
    //console.log("temp filtered data : ", tempDisplayResults);
    setDisplayResults(tempDisplayResults);
  }

  const handleLabelViewCheck =()=>{
    //event.preventDefault();
    setIsLabelViewChecked(!isLabelViewChecked);
  }

  const handleViewOptionChange=async(type)=>{
    await processResults(searchResults, type, isActiveView);
    setViewOption(type);

  }

  const handleActiveSwitchChange=async()=>{
    await processResults(searchResults, viewOption, !isActiveView);
    setIsActiveView(!isActiveView);
  }

  return (
<>
    <Helmet>
      <title>Subscriber Search - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Subscriber Search - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Subscriber Search - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>

    <Preloader show={loaded ? false : true} />
    {/*View*/}
    <div >
      <Modal onHide={toggle} show={!collapsed} size="lg">
      <div  ref={componentModelRef}>
          <Modal.Header  show={toggle} closeButton>
            Registration Form - {subscriberFormType}
                </Modal.Header>
                <Modal.Body >    
                    <SubscriberForm handleSearch={handleSearch} toggle={toggle} formType={subscriberFormType}/> 
                </Modal.Body>
                <Modal.Footer>
        {' '}
        <ReactToPrint
    content={reactToPrintContentModal}
    documentTitle="AwesomeFileName"
    
    trigger={reactToPrintTriggerModal}
  />
        <Button onClick={toggle}>
            Close
        </Button>
        </Modal.Footer>
      </div>
                
    </Modal>
    </div>

    {/*Payment*/}
    <Modal onHide={newToggle} show={!newCollapsed} size="lg">
        <Modal.Header onHide={newToggle} closeButton>
        Payment - Add
        </Modal.Header>
        <Modal.Body>    
        <div>
        <PaymentForm formType={"New"} newToggle={newToggle}/> 
          </div>
        </Modal.Body>
        <Modal.Footer>
        {' '}
        <Button onClick={newToggle}>
            Close
        </Button>
        </Modal.Footer>
    </Modal>

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><i className="fa fa-home" aria-hidden="true"></i></Breadcrumb.Item>
            <Breadcrumb.Item>Subscriber Details</Breadcrumb.Item>
            <Breadcrumb.Item active>Search</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Search Subscribers</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <ReactToPrint
            content={reactToPrintContent}
            documentTitle="student"
            trigger={reactToPrintTrigger}
          />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadSubscriberHeaders}
              filename="download.csv"
              data={searchResults}
              ref={csvLinkEl}
            />
          </ButtonGroup>
        </div>
      </div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
      <div className="d-block mb-4 mb-md-0">
      </div>
      <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <FormGroup
            inline
            check className="pr-3"
          >
            <Form.Check type="checkbox" label="Label Print View" defaultChecked={false} checked={isLabelViewChecked} onChange={handleLabelViewCheck}/>
          </FormGroup>
          <FormGroup inline check className="pr-3">
        <Form.Check
          type="radio"
          name="radioView"
          value="All"
          label="All"
          checked={viewOption==="All"}
          onClick={() => {
            handleViewOptionChange("All")
          }}
        />
      </FormGroup><span>
          <FormGroup inline check className="pr-3">
        <Form.Check
          type="radio"
          name="radioView"
          value="Email"
          label="Email"
          checked={viewOption==="Email"}
          onClick={() => {
            handleViewOptionChange("Email")
          }}
        />
      </FormGroup></span>
      <FormGroup inline check>
        <Form.Check
          type="radio"
          name="radioView"
          value="No Email"
          label="No Email"
          checked={viewOption==="No Email"}
          onClick={() => {
            handleViewOptionChange("No Email");
          }}
        />
        </FormGroup>
        </ButtonGroup>
        </div>
        </div>

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
      <div className="d-block mb-4 mb-md-0">
      </div>
      <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <FormGroup inline switch >
        <Form.Check
          type="switch"
          name="switchView"
          value="Active"
          label="Active"
          checked={isActiveView}
          onClick={() => {
            handleActiveSwitchChange(isActiveView)
          }}
        />
      </FormGroup>
        </ButtonGroup>
        </div>
        </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-start align-items-center">
        <Col md={4} className="mb-3">
              <Form.Group id="searchstud">               
                <Form.Select defaultValue="SubscriberID" value={searchTopic} onChange={(event)=>{setSearchTopic(event.target.value)}}>
                <option value="SubscriberID">Subscriber ID</option>
                <option value="SubscriberName">Subscriber Name</option>
                <option value="EmailID">Email</option>
                  <option value="Category">Category</option>
                </Form.Select>
              </Form.Group>
            </Col>
          <Col  className="mb-3" md={8}>
            <InputGroup>
              <InputGroup.Text>
              <i className="fa fa-search" aria-hidden="true"></i>
              </InputGroup.Text>
              {searchTopic==="Category"?           
              <Form.Select value={searchValue} autoFocus
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                  {journalCategory.map(item => {
                    return (
                    <option key={item.id} value={item.details}>{item.details}</option>
                    );
                  })}
            </Form.Select>:
            <Form.Control type="text"  autoFocus placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>}
              <Button variant="primary" type="submit" onClick={(event)=>{handleSearch()}}>Submit</Button>
            </InputGroup>
          </Col>
        </Row>
      </div>

     {isLabelViewChecked? <Table ref={componentRef} hover responsive>
             <thead>
              <tr>
                <th>#</th>
                <th>Name & Address</th>
              </tr>
            </thead>
            <tbody>
            {displayResults.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td><div><>Name: {item.subscriberName}</><br />
                     <>House: {item.houseName}</><br />
                     <>Address: {item.addressStreet}, <br />
                     {item.addressCity}, {item.addressState}</><br />
                     <>Pincode: {item.addressPincode}</><br />
                     <>Country: {item.country}</><br />
                     <>Contact No: {item.areaCode}-{item.contactNo}</></div></td>
            </tr>)}
            </tbody>
      </Table>:<Table ref={componentRef} hover responsive>
             <thead>
              <tr>
                <th>#</th>
                <th>Subscriber ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>End Date</th>
                <th>Status</th>
               <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {displayResults.length>0 && displayResults.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.subscriberID}</td>
            <td><div><>Name: {item.subscriberName}</><br />
                     <>House: {item.houseName}</><br />
                     <>Address: {item.addressStreet}, <br />
                     {item.addressCity}, {item.addressState}</><br />
                     <>Pincode: {item.addressPincode}</><br />
                     <>Country: {item.country}</><br />
                     <>Contact No: {item.areaCode}-{item.contactNo}</></div></td>
            <td>{item.emailID}</td>
            <td>{item.endDate}</td>
            <td>{item.subStatus==="Cancelled"?<p style={{backgroundColor:"blue", borderRadius:"10px", textAlign:"center", color:"white"}}>Cancelled</p>:
            item.subStatus==="Active"?<p style={{backgroundColor:"green", borderRadius:"10px", textAlign:"center", color:"white"}}>Active</p>:
            <p style={{backgroundColor:"red", borderRadius:"10px", textAlign:"center", color:"white"}}>Expired</p>}</td>
           <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item, "View")}}>
              <i className="fa fa-eye" aria-hidden="true"></i> View Details
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item, "Edit")}}>
              <i className="fa fa-pencil" aria-hidden="true"></i> Edit Details
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handlePaymentForm(event, item, "Add")}}>
              <i className="fa fa-plus" aria-hidden="true"></i> Add Payment
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
      </Table>}

    </>
  )
}
