import React, {useState, useContext} from 'react';
import PaymentForm from './PaymentForm';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from "axios";
import Preloader from "../../components/Preloader/Preloader";
import { ProductContext } from '../../ProductProvider';
import { Col, Row, Form, Button, InputGroup, Breadcrumb, ButtonGroup } from 'react-bootstrap';
import {Helmet} from 'react-helmet-async';
import { useSnackbar } from 'notistack';


const config = require('../../config.json');

function AddPayments() {
    const { setSubscriberFormType, setsubscriberDetails} = useContext(ProductContext);
    const [searchTopic, setSearchTopic] = useState('SubscriberID');
    const [searchValue, setSearchValue] = useState('');
    const [loaded, setLoaded] = useState(true);
    const [searchResults, setSearchResults] = useState([]);
    const [formType, setFormType]= useState("None");
    const { enqueueSnackbar } = useSnackbar();

    const handleSearch=async()=>{
        //event.preventDefault();
        setSearchResults([]);
        try{
          if(searchValue===""){
            enqueueSnackbar("Search Value is empty Enter the search input and then hit Search button!",{variant : 'warning'});
          }else{
            setLoaded(false);
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            var tsearchValue=searchValue
            if(searchTopic==="SubscriberName"){
              tsearchValue=searchValue.toUpperCase();
              setSearchValue(tsearchValue);
            }
          await axios.get(`${config.api.invokeUrl}/subscribers/globalsearch?fetchKey=${tsearchValue}&fetchOption=${searchTopic}`, {
            headers: {
              Authorization: idToken,
              'x-api-key':config.api.key
            }
          }).then(async(response) => {
            if(response.data.length>0)
            {
                setFormType("New");
                setSearchResults(response.data);
                setSubscriberFormType("Add");
                setsubscriberDetails(response.data[0]);
            }else{        
                setFormType("None");        
            }
            enqueueSnackbar(response.data.length + " - Subscriber's records found!",{variant : 'success'});
            setLoaded(true);
        }).catch((error)=>{
          enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        setLoaded(true);
      })
    }
        }catch(error){
          enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        setLoaded(true);
        }
      }
  
    return (
    <div>
    <Helmet>
      <title>Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>

    <Preloader show={loaded ? false : true} />
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><i class="fa fa-home" aria-hidden="true"></i>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Payments</Breadcrumb.Item>
            <Breadcrumb.Item active>New</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Add New Payments</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
         
         
          </ButtonGroup>
        </div>
      </div>

        <div className="table-settings mb-4">
        <Row className="justify-content-start align-items-center">
        <Col md={4} className="mb-3">
              <Form.Group id="searchstud">               
                <Form.Select defaultValue="SubscriberID" value={searchTopic} onChange={(event)=>{setSearchTopic(event.target.value)}}>
                <option value="SubscriberID">Subscriber ID</option>
                </Form.Select>
              </Form.Group>
            </Col>
          <Col  className="mb-3" md={8}>
            <InputGroup>
              <InputGroup.Text>
              <i className="fa fa-search" aria-hidden="true"></i>
              </InputGroup.Text>
            <Form.Control type="text" autoFocus placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>
              <Button variant="primary" type="submit" onClick={(event)=>{handleSearch()}}>Submit</Button>
            </InputGroup>
          </Col>
        </Row>
      </div>           
      {searchResults.length>0 && <PaymentForm formType={formType}/>}
    </div>
    );
}

export default AddPayments;