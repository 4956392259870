import React,{useEffect, useContext} from 'react'
import Card from 'react-bootstrap/Card';
import logo from '../../images/logo/logo-new.png';
import { ProductContext } from '../../ProductProvider';
import {Helmet} from 'react-helmet-async';

export default function LandingPage() {
  const { loggedUser, validateLogin } = useContext(ProductContext);

  useEffect(()=>{
    if(!loggedUser.isAuthenticated){
      validateLogin();
    }
  },[])  

  return (
    <div className="App">
    <Helmet>
      <title>Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>
        <Card>
            <Card.Body>
                <h3 className='text-info' style={{fontFamily:'sans-serif'}}>WELCOME <br/>Vidyajyoti Journal of Theological Reflection</h3>
                <img className='img-responsive' src={logo} alt='logo' width="500px" />
                <h1>Admin Portal</h1>
            </Card.Body>
        </Card>
    </div>
  )
}
