import React from 'react';
import { Row, Spinner} from 'react-bootstrap';
import { Chart } from "react-google-charts";

export default function SubscriberInsights(props) {
  const {googleData, title} = props;

   const options = {
    title: title,
    pieHole: 0.4,
    is3D: true,
    colors: ['#FFA500', '#007500', '#9E4200', '#A49393', '#555D50']
  };

  const subOptions = {
    title: title,
    pieHole: 0.4,
    is3D: false,
    //colors: ['#FFA500', '#007500', '#9E4200', '#A49393', '#555D50']
  };


  return (
  <>
    {googleData?
    <Row className="justify-content-md-center">
      <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={googleData }
      options={title==="Subscriber Status"?options:subOptions}
    />
    </Row>:<>
  <Spinner
    color="primary"
    type="grow"
  >
    Loading...
  </Spinner>
  <Spinner
    color="secondary"
    type="grow"
  >
    Loading...
  </Spinner>
  <Spinner
    color="success"
    type="grow"
  >
    Loading...
  </Spinner>
  <Spinner
    color="danger"
    type="grow"
  >
    Loading...
  </Spinner>
  <Spinner
    color="warning"
    type="grow"
  >
    Loading...
  </Spinner>
  <Spinner
    color="info"
    type="grow"
  >
    Loading...
  </Spinner>
  <Spinner
    color="light"
    type="grow"
  >
    Loading...
  </Spinner>
  <Spinner
    color="dark"
    type="grow"
  >
    Loading...
  </Spinner>
</>}
    </>
  )
}
