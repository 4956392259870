import React, {useState, useContext, useEffect} from 'react';
import { Link, useNavigate } from "react-router-dom";
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import logo from '../../images/logo/logo-new.png';
import Preloader from '../../components/Preloader/Preloader';
import PasswordStrengthIndicator from '../../components/passwordStrengthIndicator';
import { useSnackbar } from 'notistack';
import { RoutesLocal } from '../../routes';
import {Helmet} from 'react-helmet-async';
import { ProductContext } from '../../ProductProvider';

const isNumberRegx = /\d/;
const specialCharacterRegx = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
const capsRegx=/[A-Z ]+/;
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default function ForgotPassword() {
  const { validateLogin, loginValidated, setLoginValidated, loggedUser } = useContext(ProductContext);
   const navigate = useNavigate();
   const { enqueueSnackbar } = useSnackbar();
   const [username, setUsername]= useState('');
   const [confirmationCode, setConfirmationCode]= useState('');
   const [newPassword, setNewPassword] = useState('');
   const [nextPage, setNextPage]=useState(false);  
   const [loaded, setLoaded] = useState(true);
   const [newpasswordFocused, setNewPasswordFocused] = useState(false);
   const [newpasswordValidity, setNewPasswordValidity] = useState({
     minChar: null,
     number: null,
     specialChar: null,
     capsChar:null
   });
 
   useEffect(() => {
    if(!loggedUser.isAuthenticated && !loginValidated){
      setLoginValidated(true);
      validateLogin();
    }
  }, [])
 
   const handleSendAuthEmail=async(event)=>{
     event.preventDefault();
     try {
       if(username===""){
         enqueueSnackbar("Enter your registered eamil address!",{variant:'info'});
       }else if(!username.match(validRegex)){
        enqueueSnackbar("Enter your registered eamil address!",{variant:'info'});
      }else{
       setLoaded(false);
       const output = await resetPassword({ username });
       //console.log("Output : ", output);
       enqueueSnackbar("Password Reset initiated!",{variant:'info'});
       handleResetPasswordNextSteps(output);
       setLoaded(true);
       }
     } catch (error) {
       enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
       setLoaded(true);
     }  
    // setNextPage(true);
   }
 
   function handleResetPasswordNextSteps(output) {
     setLoaded(false);
     const { nextStep } = output;
     switch (nextStep.resetPasswordStep) {
       case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
         const codeDeliveryDetails = nextStep.codeDeliveryDetails;
         enqueueSnackbar(`Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`,{variant:'info'});
         setNextPage(true);
         setLoaded(true);
         // Collect the confirmation code from the user and pass to confirmResetPassword.
         break;
       case 'DONE':
         enqueueSnackbar('Password Reset Successful!',{variant:'info'});
         setLoaded(true);
         break;
     }
   
   }
 
   const handleConfirmResetPassword =async(event)=>{
     event.preventDefault();
     try {
       if(confirmationCode===""){
         enqueueSnackbar("Enter your code sent to your registered eamil address!",{variant:'info'});
       }else if(newPassword===""){
         enqueueSnackbar("Enter your new Password!",{variant:'info'});
       }else if(username===""){
         enqueueSnackbar("Enter your registered eamil address!",{variant:'info'});
       }else{
       setLoaded(false);
       await confirmResetPassword({ username, confirmationCode, newPassword })
       .then((res)=>{
         enqueueSnackbar("Your Password has been changed successfully! Login back with your New Password!",{variant:'info'});
       setLoaded(true);
       navigate("/auth/login");
       })
       .catch((error)=>{
         setLoaded(true);
         enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
       })
     }
     } catch (error) {
       setLoaded(true);
       enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
     }  
   }
 
   const onNewChangePassword = password => {
     setNewPassword(password);
     
     setNewPasswordValidity({
         minChar: password.length >= 8 ? true : false,
         number: isNumberRegx.test(password) ? true : false,
         specialChar: specialCharacterRegx.test(password) ? true : false,
         capsChar:capsRegx.test(password)?true:false
     });
     };
     
 
  return (
    <div>
    <Helmet>
      <title>Forgot Password - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Forgot Password - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Forgot Password - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>

    {!loaded &&  <Preloader show={loaded ? false : true} /> }
    <div className="full_container">
     <div className="container">
        <div className="center verticle_center full_height">
           <div className="login_section">
              <div className="logo_login">
                 <div className="center">
                    <img width="210" src={logo} alt="#" />
                 </div>
                 <h3 className="center">Admin Office - VIDYAJYOTI JOURNAL</h3>
              </div>
              <h2 className="center">Reset Password</h2>
              <div className="login_form">
                 <form>
                    <fieldset>
                       <div className="field">
                          <label className="label_field">Email Address</label>
                          <input type="email" name="email" placeholder="E-mail" 
                          onChange={event=>setUsername(event.target.value)}
                                value={username}
                                disabled={nextPage}/>
                       </div>
                       {nextPage ?<><div className="field">
                          <label className="label_field">Enter Code</label>
                          <input type="password" name="password" placeholder="Enter Code" 
                          onChange={event=>setConfirmationCode(event.target.value)}
                          value={confirmationCode}/>
                       </div><div className="field">
                          <label className="label_field">New Password</label>
                          <input type="password" name="password" placeholder="New Password" 
                          onFocus={() => setNewPasswordFocused(true)}
                          onChange={e => onNewChangePassword(e.target.value)}            
                        value={newPassword}/>
                       </div></>:null}
                       {newpasswordFocused && (
                                    <PasswordStrengthIndicator
                                        validity={newpasswordValidity}
                                    />
                                )}
                       <div className="field">
                          <label className="label_field hidden">hidden label</label>
                          {/*<label className="form-check-label"><input type="checkbox" className="form-check-input"/> Remember Me</label>*/}
                          <a className="forgot" href=""><Link to={RoutesLocal.Signin.path}>Sign In?</Link></a>
                       </div>
                       <div className="field margin_0">
                          <label className="label_field hidden">hidden label</label>
                          {!nextPage?<button className="main_bt" onClick={event=>handleSendAuthEmail(event)}>Generate Code</button>
                          :<button className="main_bt" onClick={event=>handleConfirmResetPassword(event)}>Reset Password</button>}
                       </div>
                    </fieldset>
                 </form>
              </div>
           </div>
        </div>
     </div>
    </div>
    </div>
  )
}
